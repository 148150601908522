import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { ErrorTemplate } from "../features/ui-layout/templates/ErrorTemplate";
import { LayoutLogout } from "../features/ui-layout/templates/LayoutLogout";
import { NextPageWithLayout } from "../utils/types/NextPageWithLayout";

const Error404Page: NextPageWithLayout = () => (
	<LayoutLogout title="pages.error.title">
		<ErrorTemplate />
	</LayoutLogout>
);
Error404Page.getLayout = page => page;

export default Error404Page;

/**
 *
 * @param param0 options for the page
 * @param param0.locale language locale
 * @returns props to be rendered
 */
export async function getStaticProps({ locale }: { locale: string }) {
	return { props: { ...(await serverSideTranslations(locale, ["common"])) } };
}
