import { useTranslation } from "next-i18next";

import { LogoutSection } from "../components/LogoutSection";
import { ParagraphCenter } from "../styles/textStyles";

export const ErrorTemplate = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<LogoutSection title={t("pages.error.title")} withBackground>
			<ParagraphCenter>
				{t("pages.error.description-generic")}
			</ParagraphCenter>
		</LogoutSection>
	);
};
